import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Empresa } from "smart-imob-types";
import { HBT } from "../../builder-context";
import EmpresaContext from "../../context";

class Footer_v1 extends Component {
  state = {
    uid: null,
    nome: "",
    email: "",
    tipos: []
  };
  context!: Empresa;
  componentDidMount() {
    // console.log(this.context);
    this.setState({
      uid: sessionStorage.getItem("uid") || localStorage.getItem("uid")
    });
  }

  render() {
    const tituloStyle = {
      color: this.context.palleta.cor_texto_rodape_1
    } as React.CSSProperties;
    const subtituloStyle = {
      color: this.context.palleta.cor_texto_rodape_2
    } as React.CSSProperties;

    let OrderTipos;
    if (this.context.imoveis_total_tipos) {
      OrderTipos = Object.keys(this.context.imoveis_total_tipos)
        .map(nome => {
          return {
            nome: nome,
            qtd:
              this.context.imoveis_total_tipos &&
              this.context.imoveis_total_tipos[nome]
          };
        })
        .sort((a, b) => b.qtd - a.qtd)
        .splice(0, 10);
    }
    return (
      <div className="footer-bottom">
        <div className="row">
          <div className={`col-lg-${this.state.uid ? "4" : "3"} col-sm-6`}>
            <div className="widget widget_nav_menu">
              <h4
                style={
                  this.context.palleta.use_color &&
                  this.context.palleta.cor_texto_rodape_1
                    ? tituloStyle
                    : undefined
                }
                className="widget-title"
              >
                Buscas por tipo
              </h4>
              <ul>
                {OrderTipos
                  // .slice(0, 3)
                  .map((tipo, i) => (
                    <li key={tipo.nome + "_" + i} className="readeal-top">
                      <Link
                        style={
                          this.context.palleta.use_color &&
                          this.context.palleta.cor_texto_rodape_2
                            ? subtituloStyle
                            : undefined
                        }
                        to={{
                          pathname: "/lista",
                          state: {
                            tipo: [
                              {
                                value: tipo.nome,
                                label: tipo.nome
                              }
                            ]
                          }
                        }}
                      >
                        {tipo.nome}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          {this.context.links_extras &&
          this.context.links_extras.links &&
          this.context.links_extras.links.length > 0 ? (
            <div className={`col-lg-${this.state.uid ? "4" : "3"} col-sm-6`}>
              <div className="widget widget_nav_menu">
                <h4
                  style={
                    this.context.palleta.use_color &&
                    this.context.palleta.cor_texto_rodape_1
                      ? tituloStyle
                      : undefined
                  }
                  className="widget-title"
                >
                  {this.context.links_extras.titulo || "Links extras"}
                </h4>
                <ul>
                  {this.context.links_extras.links.map(link => (
                    <li key={"footer_" + link.nome} className="readeal-top">
                      <a
                        style={
                          this.context.palleta.use_color &&
                          this.context.palleta.cor_texto_rodape_2
                            ? subtituloStyle
                            : undefined
                        }
                        target="_blank"
                        rel="noopener"
                        href={link.url}
                      >
                        {link.nome}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            <div className={`col-lg-${this.state.uid ? "4" : "3"} col-sm-6`}>
              <div className="widget widget_nav_menu">
                <h4
                  style={
                    this.context.palleta.use_color &&
                    this.context.palleta.cor_texto_rodape_1
                      ? tituloStyle
                      : undefined
                  }
                  {...HBT("tests_b")}
                  className="widget-title"
                >
                  {this.context["tests_b"] || "Quer anunciar?"}
                </h4>
                <ul>
                  <li className="readeal-top">
                    <Link
                      style={
                        this.context.palleta.use_color &&
                        this.context.palleta.cor_texto_rodape_2
                          ? subtituloStyle
                          : undefined
                      }
                      to="/registrar-imovel"
                    >
                      Enviar imóvel
                    </Link>
                  </li>
                  <li className="readeal-top">
                    <Link
                      style={
                        this.context.palleta.use_color &&
                        this.context.palleta.cor_texto_rodape_2
                          ? subtituloStyle
                          : undefined
                      }
                      to="/contato"
                    >
                      Fale com nossa equipe
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          )}
          <div className={`col-lg-${this.state.uid ? "4" : "3"} col-sm-6`}>
            <div className="widget widget_nav_menu">
              <h4
                style={
                  this.context.palleta.use_color &&
                  this.context.palleta.cor_texto_rodape_1
                    ? tituloStyle
                    : undefined
                }
                className="widget-title"
              >
                Links rápidos
              </h4>
              <ul>
                <li className="readeal-top">
                  <Link
                    style={
                      this.context.palleta.use_color &&
                      this.context.palleta.cor_texto_rodape_2
                        ? subtituloStyle
                        : undefined
                    }
                    to="/sobre"
                  >
                    Sobre nós
                  </Link>
                </li>
                <li className="readeal-top">
                  <Link
                    style={
                      this.context.palleta.use_color &&
                      this.context.palleta.cor_texto_rodape_2
                        ? subtituloStyle
                        : undefined
                    }
                    to="/contato"
                  >
                    Contato
                  </Link>
                </li>
                <li className="readeal-top">
                  <Link
                    style={
                      this.context.palleta.use_color &&
                      this.context.palleta.cor_texto_rodape_2
                        ? subtituloStyle
                        : undefined
                    }
                    to="/lista"
                  >
                    Busca
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          {!this.state.uid && (
            <div className="col-lg-3 col-sm-6">
              <div className="widget widget-subscribe">
                <div className="rld-single-input">
                  <label style={{ fontSize: 0, width: "100%" }}>
                    Nome
                    <input
                      type="text"
                      name="name"
                      value={this.state.nome}
                      onChange={e => this.setState({ nome: e.target.value })}
                      placeholder="Seu nome"
                    />
                  </label>
                </div>
                <div className="rld-single-input">
                  <label style={{ fontSize: 0, width: "100%" }}>
                    Email
                    <input
                      type="text"
                      name="email"
                      value={this.state.email}
                      onChange={e => this.setState({ email: e.target.value })}
                      placeholder="seu@email.com"
                    />
                  </label>
                </div>
                <Link
                  to={{
                    pathname: "/contato",
                    state: {
                      email: this.state.email,
                      nome: this.state.nome
                    }
                  }}
                  className="btn btn-yellow w-100"
                >
                  Entre em contato
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Footer_v1.contextType = EmpresaContext;
export default Footer_v1;
